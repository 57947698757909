"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapProjectWithProgressToProject = void 0;
const mapProjectWithProgressToProject = ({ progress, project, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return {
        id: project.id,
        projectTemplateId: project.projectTemplate.id,
        projectScheduleId: project.projectScheduleId,
        name: project.projectTemplate.name,
        subtitle: ((_a = project.projectTemplate) === null || _a === void 0 ? void 0 : _a.subtitle) || null,
        repositoryOwner: project.repositoryOwner || null,
        repositoryName: project.repositoryName || null,
        teamId: project.teamId || null,
        type: project.projectTemplate.type,
        theme: project.projectTemplate.theme,
        maxCompletionTimeHours: ((_b = project === null || project === void 0 ? void 0 : project.projectSchedule) === null || _b === void 0 ? void 0 : _b.maxCompletionTimeHours) || null,
        startedAt: project.startedAt,
        groupId: ((_d = (_c = project === null || project === void 0 ? void 0 : project.projectSchedule) === null || _c === void 0 ? void 0 : _c.program) === null || _d === void 0 ? void 0 : _d.groupId) || "",
        showInPortfolio: project.showInPortfolio,
        deploymentUrl: (project === null || project === void 0 ? void 0 : project.deploymentUrl) || null,
        briefId: project.projectTemplate.briefId,
        state: project.state,
        eta: project.projectTemplate.eta,
        isPublic: (_e = project === null || project === void 0 ? void 0 : project.projectSchedule) === null || _e === void 0 ? void 0 : _e.isPublic,
        difficultyLevel: project.projectTemplate.difficultyLevel,
        scheduledLessons: ((_f = project === null || project === void 0 ? void 0 : project.projectSchedule) === null || _f === void 0 ? void 0 : _f.lessonSchedules) || [],
        tags: !!((_h = (_g = project.projectTemplate) === null || _g === void 0 ? void 0 : _g.tags) === null || _h === void 0 ? void 0 : _h.length)
            ? project.projectTemplate.tags.map((tag) => tag.name)
            : [],
        completedTasks: progress.completedTasksAmount,
        scheduledTasks: progress.scheduledTasksAmount,
    };
};
exports.mapProjectWithProgressToProject = mapProjectWithProgressToProject;
