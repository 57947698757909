"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_GROUP_CANDIDATES = void 0;
const core_1 = require("@apollo/client/core");
exports.FIND_GROUP_CANDIDATES = (0, core_1.gql) `
  query FindGroupCandidates($groupId: String!) {
    findGroupCandidates(groupId: $groupId) {
      userId
      fullName
      avatarUrl
      email
      groupName
      githubUrl
      linkedInUrl
      phone
      languages
      technologies
      createdAt
      projects {
        id
        title
        completionTime
      }
    }
  }
`;
