"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGenerateProjectRepo = void 0;
const client_1 = require("@apollo/client");
const generateProjectRepository_1 = require("../queries/generateProjectRepository");
const useGenerateProjectRepo = () => {
    const [genProjectRepo, { loading }] = (0, client_1.useMutation)(generateProjectRepository_1.GENERATE_PROJECT_TEMPLATE_REPO);
    const generateProjectRepo = (projectTemplateId) => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield genProjectRepo({
            variables: { projectTemplateId },
            update: (cache, { data }) => {
                //Update project repo in cache
                if (!(data === null || data === void 0 ? void 0 : data.generateProjectRepository))
                    return;
                const projectTemplateToUpdate = {
                    id: projectTemplateId,
                    __typename: "ProjectTemplateEntity",
                };
                cache.modify({
                    id: cache.identify(projectTemplateToUpdate),
                    fields: {
                        githubRepoTemplateName(prev) {
                            return (data.generateProjectRepository.githubRepoTemplateName || prev);
                        },
                    },
                });
            },
        });
        return (data === null || data === void 0 ? void 0 : data.generateProjectRepository) || null;
    });
    return {
        generateProjectRepo,
        loading,
    };
};
exports.useGenerateProjectRepo = useGenerateProjectRepo;
