"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_TASKS = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_TASKS = (0, client_1.gql) `
  mutation UpdateTasks($data: [JSONObject!]!) {
    updateTasks(input: { data: $data }) {
      id
    }
  }
`;
