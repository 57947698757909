"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialProjectValue = void 0;
const graphql_1 = require("../types/graphql");
exports.initialProjectValue = {
    completedTasks: 0,
    id: "",
    name: "",
    teamId: null,
    projectScheduleId: "",
    repositoryOwner: "",
    repositoryName: "",
    projectTemplateId: "",
    briefId: "",
    maxCompletionTimeHours: null,
    showInPortfolio: false,
    deploymentUrl: null,
    scheduledLessons: [],
    startedAt: "",
    subtitle: null,
    groupId: "",
    isPublic: false,
    scheduledTasks: 0,
    eta: 0,
    state: "",
    difficultyLevel: "",
    tags: [],
    theme: graphql_1.Theme.DEFAULT,
    type: graphql_1.ProjectType.SANDBOX,
};
